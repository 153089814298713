import React from "react";
import styles from "../../assets/css/landingpage/footer.module.css";


const FooterSection = () => {
    return (
        <>  
        <footer className={styles.footer}>
            <div className={styles.footerContainer}>
                <div className={styles.footerLogo}>
                    <img 
                        src="img/logo_blanco_fondo_transparente.webp"
                        width="" 
                        height=""  
                        alt="Logo de Pallevar" 
                    />
                </div>

                <div className={styles.footerColumns}>
                    <div className={styles.footerColumn}>
                        <h4>Síguenos</h4>
                        <ul className={styles.socialLinks}>
                            <li><a href="https://www.facebook.com/profile.php?id=61567145556569"  target="_blanks"><i className="fab fa-facebook-f"></i> Facebook</a></li>
                            <li><a href="https://www.linkedin.com/company/pallevar/" target="_blanks"><i className="fab fa-linkedin-in"></i> LinkedIn</a></li>
                            <li><a href="https://www.instagram.com/pallevar.me/" target="_blanks"><i className="fab fa-instagram"></i> Instagram</a></li>
                        </ul>
                    </div>

                    <div className={styles.footerColumn}>
                        <h4>Legales</h4>
                        <ul className={styles.legalLnks}>
                            <li><a href="/privacy">Política de Privacidad</a></li>
                            <li><a href="/terms">Términos y Condiciones</a></li>
                            <li><a href="/cookiesPolicy">Política de Cookies</a></li>
                            <li><a href="/legal">Aviso legal</a></li>
                        </ul>
                    </div>

                    <div className={styles.footerColumn}>
                        <h4>Explora</h4>
                        <ul className={styles.exploreLinks}>
                            <li><a href="https://docs.espallevar.com/" target="_blanks">Documentacion</a></li>
                            <li><a href="https://jobs.espallevar.com/" target="_blanks">Trabajo</a></li>
                            <li><a href="https://lacomanda.espallevar.com/" target="_blanks">Comanda</a></li>
                            <li><a href="https://admin.espallevar.com/" target="_blanks">Panel administrativo</a></li>
                        </ul>
                    </div>
                </div>
            </div>

        
            <div className={styles.footerBottom}>
                <p>&copy; 2025 Pallevar. Todos los derechos reservados.</p>
            </div>
        </footer>

        </>
    );
    };
    
    export default FooterSection;
    