
import React from 'react';
import NavBar from '../components/landingpage/MenuNavbar';
import HeroSection from '../components/landingpage/HeroSection';
import PlatformSection from '../components/landingpage/PlatformSection';
import BenefitsSection from '../components/landingpage/BenefitsSection';
import PricingSection from '../components/landingpage/PricingSection';
import ContactSection from '../components/landingpage/ContactSection';
import FooterSection from '../components/landingpage/FooterSection';
 
const LandingPage = () => {
    return (  
        <>
          <head>
            <title>Menús Digitales y Órdenes en Línea | Pallevar</title>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Archivo+Black&family=Inter:wght@100..900&display=swap"
            />
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
            />
          </head>
           <NavBar />
           <HeroSection />
           <PlatformSection />
           <BenefitsSection />
           <PricingSection />
           <ContactSection />
           <FooterSection />
        </>
  );
};

export default LandingPage;
