import React from "react";
import styles from "../assets/css/menutabs.module.css";

const CategoryTabs = ({ menuColor, activeTab, onTabClick }) => {
  return (
    <div className={styles.tabs}>
      {['entradas', 'comidas', 'bebidas', 'postres'].map((tab) => (
        <div
          key={tab}
          className={`${styles.tab} ${
            activeTab === tab ? styles.activeTab : ''
          }`}
          style={{ '--menu-color': menuColor }}
          onClick={() => onTabClick(tab)}
        >
          {tab.charAt(0).toUpperCase() + tab.slice(1)} {/* Capitaliza el nombre */}
        </div>
      ))}
    </div>
  );
};

export default CategoryTabs;
