import React from 'react';
import styles from '../assets/css/navbar.module.css';  

const Navbar = ({ title, menuColor, openCartModal, cartItemCount }) => {
  let titleUppercase = title.toUpperCase();

  return (
    <div className={styles.menuBar} style={{ backgroundColor: menuColor }}>
      <h1 className={styles.title}>{titleUppercase}</h1>
      <div className={styles.links}>
        {/* 
        <a href="#comida" className={styles.link}>Comida</a>
        <a href="#bebidas" className={styles.link}>Bebidas</a>
        <a href="#postres" className={styles.link}>Postres</a>
        */}
      </div>
      <div className={styles.cartIcon} onClick={openCartModal}>
        🛒Carrito&nbsp;
        {cartItemCount > 0 && (
          <span className={styles.cartCount}>{cartItemCount}</span>
        )}
      </div>
    </div>
  );
};

export default Navbar;
