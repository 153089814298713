import React from "react";
import styles from "../../assets/css/landingpage/benefits.module.css";


const BenefitsSection = () => {
    return (
        <section className={styles.benefitsSection} id="benefits">
            <h2>Impulsa tu negocio con Pallevar</h2>
            <p className={styles.benefitsIntro}>Nuestro sistema está diseñado para ayudarte a simplificar, crecer y transformar tu negocio de comida. Descubre cómo podemos hacer la diferencia.</p>
            
            <div className={styles.benefitsContainer}>
                <div className={styles.benefitItem}>
                    <div className={styles.benefitIcon}>💼</div>
                    <h3>Gestión Eficiente</h3>
                    <p>Optimiza tus operaciones diarias, reduce errores y mantén todo bajo control con un panel administrativo completo y fácil de usar.</p>
                </div>
                
                <div className={styles.benefitItem}>
                    <div className={styles.benefitIcon}>📈</div>
                    <h3>Incrementa Tus Ventas</h3>
                    <p>Atrae más clientes con un menú digital y mejora la experiencia del cliente desde el primer pedido.</p>
                </div>
                
                <div className={styles.benefitItem}>
                    <div className={styles.benefitIcon}>⏱️</div>
                    <h3>Ahorra Tiempo</h3>
                    <p>Automatiza la toma de pedidos, agiliza los tiempos de entrega y dedica más tiempo a lo que realmente importa.</p>
                </div>
        
                <div className={styles.benefitItem}>
                    <div className={styles.benefitIcon}>💡</div>
                    <h3>Datos en Tiempo Real</h3>
                    <p>Obtén estadísticas detalladas y toma decisiones estratégicas para mejorar tus operaciones y ganancias.</p>
                </div>
            </div>
        </section>
        );
    };
    
    export default BenefitsSection;
    