import React from "react";
import NavBar from "../components/landingpage/MenuNavbar";
import FooterSection from "../components/landingpage/FooterSection";
import styles from "../assets/css/privacy.module.css";

const LegalPage = () => {    
    return (
        <>
            <NavBar />
            <section className={styles.main}>
                <div className={styles.container}>
                    <h1>Aviso legal sobre el uso de la marca Pallevar</h1>
                    <p>
                        La marca <strong>Pallevar</strong>, es una propiedad intelectual registrada 
                        ante el Instituto Mexicano de la Propiedad Industrial (IMPI) y está protegida por la legislación vigente en México. 
                        Cualquier uso no autorizado, reproducción, imitación o intento de confusión con nuestra marca, ya sea en nombre comercial, 
                        dominio web o cualquier otro medio, será considerado una violación a nuestros derechos.
                    </p>
                    <p>
                        Nos reservamos el derecho de tomar acciones legales inmediatas contra cualquier persona o entidad que haga uso 
                        indebido de la marca <strong>Pallevar</strong> sin autorización expresa. Esto incluye, pero no se limita a, demandas por infracción de derechos de propiedad industrial, 
                        reclamaciones por competencia desleal y solicitudes de retiro de contenido en plataformas digitales.
                    </p>
                    <p>
                        En <strong>Pallevar</strong>, monitoreamos activamente cualquier acción que pueda comprometer la integridad de nuestra propiedad intelectual. 
                        El uso de nuestra identidad corporativa, nombre o marca con fines ajenos a los establecidos en nuestros términos y condiciones, 
                        ya sea de manera directa o encubierta, dará lugar a consecuencias legales.
                    </p>
                    <p>
                        Las coincidencias no fortuitas, donde la inspiración cruza un límite y se convierte en una apropiación indebida, constuyen una infracción penada por la ley.
                    </p>
                </div>
            </section>
            <FooterSection />
        </>
    );
};

export default LegalPage;
