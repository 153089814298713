import React, { useState, useRef } from "react";
import styles from "../../assets/css/landingpage/navbar.module.css";

const NavBar = () => {
  const [isSidebarActive, setSidebarActive] = useState(false); // Estado para la barra lateral
  const sidebarRef = useRef(null); // Referencia al contenedor de la barra lateral

  // Función para alternar la barra lateral con el botón de hamburguesa
  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };

  // Función para cerrar la barra lateral
  const closeSidebar = () => {
    setSidebarActive(false);
  };

  // Renderiza el componente
  return (
    <>
      {/* Navegación principal */}
      <div className={styles.globalnavWrapperC360}>
        <nav className={styles.c360nav}>
          <div className={styles.c360navHeader}>
            {/* Botón de menú hamburguesa */}
            <div
              className={styles.burgerMenu}
              id="burger-menu"
              onClick={toggleSidebar}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>

            {/* Logo */}
            <div className={styles.logo}>
              <img
                src="img/pallevar-logo-2.webp"
                alt="Logo"
                width="" 
                height="" 
              />
            </div>

            {/* Enlaces de navegación */}
            <ul className={styles.navLinks1}>
              <li>
                <a href="#home">Inicio</a>
              </li>
              <li>
                <a href="#services">Servicios</a>
              </li>
              <li>
                <a href="#precios">Precios</a>
              </li>
              <li>
                <a href="#contact">Contacto</a>
              </li>
              <li>
                <button 
                className={styles.dropdownButton} 
                onClick={() => window.open("https://admin.espallevar.com/", "_blank")}
                >
                  Iniciar sesión
                </button>
                <div className={styles.dropdown}>
                  <a href="https://admin.espallevar.com/">Panel</a>
                  <a href="https://lacomanda.espallevar.com/">Comanda</a>
                </div>
              </li>
            </ul>

            <div className={styles.dropdown2}>
                    <button 
                        className={styles.dropdownButton2}
                        onClick={() => window.open("https://admin.espallevar.com/", "_blank")}
                    >
                        Iniciar sesión
                    </button>
                    <div className={styles.dropdownContent2} id="dropdownContent2">
                        <a href="https://admin.espallevar.com/">Panel</a>
                        <a href="https://lacomanda.espallevar.com/">Comanda</a>
                    </div>
                </div>
            {/* Botón de "Empezar gratis" */}
            <button
              className={`${styles.dropdownButton2} ${styles.trial}`}
              onClick={() => window.open("https://admin.espallevar.com/signup", "_blank")}
            >
              Empezar gratis
            </button>
          </div>
        </nav>
      </div>

      {/* Barra lateral */}
      <div
        className={`${styles.sidebar} ${
          isSidebarActive ? styles.active : ""
        }`}
        id="sidebar"
        ref={sidebarRef}
      >
        <ul>
          <li>
            <a href="#home" onClick={closeSidebar}>
              Inicio
            </a>
          </li>
          <li>
            <a href="#about" onClick={closeSidebar}>
              Acerca de
            </a>
          </li>
          <li>
            <a href="#services" onClick={closeSidebar}>
              Servicios
            </a>
          </li>
          <li>
            <a href="#precios" onClick={closeSidebar}>
              Precios
            </a>
          </li>
          <li>
            <a href="#contact" onClick={closeSidebar}>
              Contacto
            </a>
          </li>
          <li>
            <button 
                className={styles.dropdownButton} 
                onClick={() => window.open("https://admin.espallevar.com/signup", "_blank")}
                >
              Empezar gratis
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavBar;
