import React from "react";
import styles from '../assets/css/footer.module.css';  

const Footer = ({ title, menuColor }) => {
  return (
    <footer className={styles.footer} style={{ backgroundColor: menuColor }}>
      <p>&copy; {new Date().getFullYear()} {title}. Todos los derechos reservados.</p>
    </footer>
  );
};



export default Footer;
