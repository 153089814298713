import React, { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

const FoodMenu = ({id, platillo, precio, img, onViewDetails, onAddToCart, hide  }) => {
  const isHidden = hide;
  const [isButtonPressed, setIsButtonPressed] = useState(false);

  return ( 
    isHidden && (
    <div
      style={styles.menuItem}
      key={id}
      onMouseEnter={(e) => (e.currentTarget.style.transform = styles.menuItemHover.transform)}
      onMouseLeave={(e) => (e.currentTarget.style.transform = "")}  
    >
      <img 
        src={img} 
        alt={platillo} 
        style={styles.img} 
        onClick={onViewDetails}
        loading="lazy"
        width="" 
        height="" 
      />
      <h3 style={styles.title}>{platillo}</h3>
      <span style={styles.price}>${precio} MXN</span>
      <button 
        style={isButtonPressed ? { ...styles.button, ...styles.buttonClickFeedback } : styles.button}
        onMouseDown={() => setIsButtonPressed(true)}
        onMouseUp={() => setIsButtonPressed(false)} 
        onClick={onAddToCart}
      >
        <i className ="bi bi-cart-fill"></i>&nbsp;
        Agregar al carrito
      </button>
      <button style={styles.button2} onClick={onViewDetails} > Ver detalle </button>
    </div>
    )
   );
};

const styles = {
    menuItem: {
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      transition: "transform 0.3s",
      cursor: "pointer",
    },
    menuItemHover: {
      transform: "translateY(-5px)",
    },
    img: {
      width: "100%",
      height: "150px",
      objectFit: "cover",
      borderRadius: "8px",
      border: "1px solid #eee",
    },
    title: {
      margin: "10px 0",
      color: "#333",
    },
    description: {
      color: "#666",
      fontSize: "14px",
      margin: "10px 0",
    },
    price: {
      color: "#FE61DC",
      fontWeight: "bold",
      fontSize: "18px",
      display: "block",
      marginBottom: "10px",
    },
    button: {
      backgroundColor: "#0065FF",
      border: "none",
      padding: "10px",
      color: "white",
      cursor: "pointer",
      borderRadius: "4px",
      margin: "5px 0",
      width: "100%",
      transition: "background-color 0.3s",
    },
    button2: {
      backgroundColor: "#002b6b",
      border: "none",
      padding: "10px",
      color: "white",
      cursor: "pointer",
      borderRadius: "4px",
      margin: "5px 0",
      width: "100%",
      transition: "background-color 0.3s",
    },
    buttonClickFeedback: {
      backgroundColor: "#3b7edb",
    },
    buttonHover: {
      backgroundColor: "#0053cc",
    },
  };

export default FoodMenu;