import React, { useState } from "react";

const Modal = ({ item, onClose, onAddToCart }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Cierra el modal si se hace clic en el fondo
    }
  };
 
  const [isButtonPressed, setIsButtonPressed] = useState(false);

  return (
    <div style={styles.overlay} onClick={handleOverlayClick}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>X</button>
        <img src={item.imgPlatillo} alt={item.nombrePlatillo} style={styles.img} />
        <h2>{item.nombrePlatillo}</h2>
        <p>
          <pre  style={styles.pre}>
            {item.descripcion}
          </pre>
        </p>
        <p><strong>Precio:</strong> ${item.precio} MXN</p>
        <p><strong>Categoría:</strong> {item.categoria}</p>
        <button 
          style={isButtonPressed ? { ...styles.addToCartButton, ...styles.addToCartButtonClickFeedback } : styles.addToCartButton}
          onMouseDown={() => setIsButtonPressed(true)}
          onMouseUp={() => setIsButtonPressed(false)} 

          onClick={onAddToCart}
        >
          <i className ="bi bi-cart-fill"></i>&nbsp;
          Agregar al carrito
        </button>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    position: "relative",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "90%",
    maxWidth: "400px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    textAlign: "center",

    maxHeight: "95vh",  // Limit the maximum height to 95% of the viewport height
    overflowY: "auto",  // Allow internal scrolling if the content exceeds the max height
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "5px",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "16px",
    cursor: "pointer",
  },
  img: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
    borderRadius: "8px",
    marginBottom: "15px",
  },
  addToCartButton: {
    marginTop: "10px",
    padding: "10px",
    backgroundColor: "#0065FF",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    width: "100%",
  },
  addToCartButtonClickFeedback: {
    backgroundColor: "#3b7edb",
  },
  pre: {
    textAlign: "center",
    fontFamily: 'Arial, sans-serif',
    fontSize: "20px",
    letterSpacing: "-.0012em",
    lineHeight: "30px",

    width: "100%",  // Prevent overflow from parent container
    wordWrap: "break-word",  // Ensures words break and wrap
    overflowWrap: "break-word",  // modern long word wrap
    whiteSpace: "pre-wrap",  // long lines wrapping
  }
};

export default Modal;
