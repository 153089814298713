import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../assets/css/searchOrder.module.css';  
import { statusOrden } from '../services/authService';

const BuscarOrden = () => {
    const { id } = useParams();
    const [orderId, setOrderId] = useState(''); // Texto ingresado por el usuario
    const [orderData, setOrderData] = useState(null); // Datos de la orden retornada
    const [loading, setLoading] = useState(false); // Indicador de carga
    const [error, setError] = useState(null); // Manejo de errores

    const handleSearch = async () => {
        if (!orderId.trim()) {
            setError('Por favor, ingresa un número de orden válido.');
            return;
        }

        setError(null);
        setLoading(true);
        setOrderData(null);

        try {
            const response = await statusOrden(id, orderId); // Llama la API
            if (response.status === 200) {
                setOrderData(response.data); // Guarda los datos retornados
            } else {
                setError('No se encontró ninguna orden con ese número.');
            }
        } catch (err) {
            setError('Ocurrió un error al buscar la orden. Intenta nuevamente.');
        } finally {
            setLoading(false);
        }
    };

    return (
<div className={styles.container}>
    <h1 className={styles.title}>Buscar Orden</h1>
    <p className={styles.description}>
        Ingresa el número de orden para buscar el estado y detalles asociados.
    </p>
    <div className={styles.searchBox}>
        <input
            type="text"
            placeholder="Número de orden"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            className={styles.input}
        />
        <button onClick={handleSearch} className={styles.button}>
            Buscar
        </button>
    </div>
    {loading && <p className={styles.loading}>Buscando orden...</p>}
    {error && <p className={styles.error}>{error}</p>}
    {orderData && (
        <div className={styles.orderDetails}>
            <h2 className={styles.detailsTitle}>Detalles de la Orden</h2>
            <p>
                <strong>Pedido ID:</strong> {orderData.orderId}
            </p>
            <p>
                <strong>Estado:</strong>{" "}
                <span className={`${styles.orderStatus} ${styles[`status-${orderData?.orderStatus?.toLowerCase()}`]}`}>
                    {orderData.orderStatus}
                </span>
            </p>
            <p>
                <strong>Cliente:</strong> {orderData.customerName}
            </p>
            <p>
                <strong>Total:</strong> ${orderData.totalPrice} MXN
            </p>
            <h3 className={styles.itemsTitle}>Artículos</h3>
            <ul className={styles.itemsList}>
                {orderData.items.map((item, index) => (
                    <li key={index} className={styles.item}>
                        {item.name} <span>x{item.quantity}</span>
                    </li>
                ))}
            </ul>
            <p>
                <strong>Tiempo Estimado de Entrega:</strong> {orderData.estimatedDeliveryTime}
            </p>
        </div>
    )}
</div>

    );
};

export default BuscarOrden;
