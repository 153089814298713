import React from "react";
import styles from "../../assets/css/landingpage/contact.module.css";


const ContactSection = () => {
    return (
        <>  
      <section className={styles.contactSection} id="contact">
    <div className={styles.contactContent}>
        <div className={styles.contactHeader}>
            <h2>Contáctanos</h2>
            <p className={styles.contactIntro}>Estamos listos para ayudarte a transformar tu negocio de comida. ¡Hablemos!</p>
        </div>

        <div className={styles.contactContainer}>
            

            <div className={styles.contactInfo}>
                <div className={styles.contactItem}>
                    <h3>Oficina Principal</h3>
                    <p> Blvd. de las Américas Pte. 12414,<br/>El Paraiso, 22106 Tijuana, B.C., Mexico</p> 
                    <a href="tel:+24444561256" className={styles.contactCall}>+52 664 877 6301</a>
                    <a href="mailto:soporte@espallevar.com" className={styles.contactEmail}>soporte@espallevar.com</a>
                </div>
                <div className={styles.contactItem}>
                    <h3>Ayuda y Soporte</h3>
                    <p>Estamos aquí para ayudarte con cualquier pregunta.</p>
                    <a href="/" className={styles.contactCall}>Obtener soporte</a>
                </div>
                <div className={styles.contactItem}>
                    <h3>Comunidad</h3>
                    <div className={styles.contactSocial}>
                        <a href="https://www.facebook.com/profile.php?id=61567145556569" target="_blanks" className={styles.contactSocialItem}>
                            <img src="https://cdn.prod.website-files.com/647444d4107043aab0cb4c7b/6478cf65be46106da2284a98_facebook.svg" alt="Facebook" />
                            <div>Facebook</div>
                        </a>
                        <a href="https://www.instagram.com/pallevar.me/" target="_blanks" className={styles.contactSocialItem}>
                            <img src="https://cdn.prod.website-files.com/647444d4107043aab0cb4c7b/6478cf65be46106da2284a9b_instagram-dark.svg" alt="Instagram" />
                            <div>Instagram</div>
                        </a>
                        <a href="https://www.linkedin.com/company/pallevar/" target="_blanks" className={styles.contactSocialItem}>
                            <img src="https://cdn.prod.website-files.com/647444d4107043aab0cb4c7b/6478cf65be46106da2284a9a_linkedin.svg" alt="Linkedin" />
                            <div>Linkedin</div>
                        </a>
                    </div>
                </div>
            </div>


            <div className={styles.contactForm}>
                <h5>Envíanos un mensaje</h5>
                <form id="wf-form-Contact-Form" name="wf-form-Contact-Form" method="get">
                    <div className={styles.fieldGroup}>
                        <label htmlFor="name">Nombre</label>
                        <input className="field-input" name="name" type="text" id="name" />
                    </div>
                    <div className={styles.fieldGroup}>
                        <label htmlFor="email">Correo Electrónico</label>
                        <input className="field-input" name="email" type="email" id="email" required />
                    </div>
                    <div className={styles.fieldGroup}>
                        <label htmlFor="subject">Asunto</label>
                        <input className="field-input" name="subject" type="text" id="subject" />
                    </div>
                    <div className={styles.fieldGroup}>
                        <label htmlFor="message">Mensaje</label>
                        <textarea id="message" name="message"></textarea>
                    </div>
                    <input type="submit" className={styles.contactButton} value="Enviar" />
                </form>
            </div>
        </div>
    </div>
</section>
        </>
    );
    };
    
    export default ContactSection;
    