import React from "react";
import styles from "../../assets/css/landingpage/hero.module.css";


const HeroSection = () => {
    return (
        <>  
            <section className={styles.hero} id="home">
        <div className={styles.container}>
        
            <div className={styles.row}>
            <div className={`${styles.colmd6} ${styles.heroContent}`}>
                    <h1>Transforma tu restaurante con pedidos en línea sencillos y eficientes</h1>
                    <p>Optimiza cada aspecto de tu servicio: gestiona pedidos, conecta con tus clientes y aumenta tus ingresos sin intermediarios. Todo desde una sola plataforma y sin complicaciones.</p>
                    
    
                    <div className={styles.buttonGroup}>
                        <button 
                            className={styles.dropdownButton2}
                            onClick={() => window.open("https://admin.espallevar.com/", "_blank")}
                        >
                            Comenzar Ahora
                        </button>
                        <button
                            className={styles.dropdownButton}
                            onClick={() => {
                                const section = document.getElementById("precios");
                                if (section) {
                                section.scrollIntoView({ behavior: "smooth" });
                                }
                            }}
                            >
                            Ver Precios
                        </button>
                    </div>
                </div>
              
                <div className={styles.colmd6}>
                    <img 
                        src="img/foodtruck.webp" 
                        alt="Foodtruck" 
                        width="100%" 
                        height="auto"
                        loading="lazy"
                        className={styles.heroImage}
                    />
                </div>
            </div>
        </div>
    </section>
        </>
    );
    };
    
    export default HeroSection;
    