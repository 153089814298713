// Header.jsx
import React from 'react';

const Header = ({
  menuColor,
  horarioAtencion
}) => {
  const styles = {
    header: {
      backgroundColor: menuColor,
      color: 'white',
      textAlign: 'center',
      padding: '1rem',
      marginTop: '70px',
    },
    headerText: {
      margin: 0,
      fontSize: '24px',
    },
    scheduleText: {
      margin: 0,
      fontSize: '15px',
    },
  };
  return (
    <header style={styles.header}>
      <h1 style={styles.headerText}>Menú Digital</h1>
      <p style={styles.scheduleText}>{horarioAtencion}</p>
    </header>
  );
};



export default Header;
