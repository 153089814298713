import React from "react";
import NavBar from "../components/landingpage/MenuNavbar";
import FooterSection from "../components/landingpage/FooterSection";
import styles from "../assets/css/privacy.module.css";

const TerminosPage = () => {    
    return (
        <>
            <NavBar />
            <section className={styles.main}>
            <div className={styles.container}>
            <h1>Términos y Condiciones</h1>
                <p>
                    Bienvenido a <strong>Pallevar</strong>. Estos Términos y Condiciones regulan el uso de nuestra plataforma. 
                    Al acceder o utilizar nuestros servicios, aceptas cumplir con estos términos. 
                    Si no estás de acuerdo, no utilices nuestros servicios.
                </p>

                <h2>1. Definiciones</h2>
                <p>
                    En este documento:
                </p>
                <ul>
                    <li><strong>"Plataforma":</strong> Se refiere al sitio web y servicios de Pallevar.</li>
                    <li><strong>"Usuario":</strong> Cualquier persona que acceda o utilice nuestra plataforma.</li>
                    <li><strong>"Establecimiento":</strong> Restaurantes u otros negocios que utilizan nuestra plataforma.</li>
                </ul>

                <h2>2. Uso de la plataforma</h2>
                <p>
                    El uso de nuestra plataforma está sujeto a las siguientes reglas:
                </p>
                <ul>
                    <li>Debes proporcionar información precisa al registrarte o realizar un pedido.</li>
                    <li>No está permitido usar nuestra plataforma para actividades ilícitas o no autorizadas.</li>
                    <li>Nos reservamos el derecho de suspender o cancelar cuentas en caso de incumplimiento de estos términos.</li>
                </ul>

                <h2>3. Responsabilidad</h2>
                <p>
                    <strong>Pallevar</strong> no se hace responsable de:
                </p>
                <ul>
                    <li>Errores en los pedidos causados por información incorrecta proporcionada por el usuario.</li>
                    <li>Problemas relacionados con la preparación o entrega de los pedidos, que son responsabilidad del establecimiento.</li>
                    <li>Interrupciones en la plataforma debido a mantenimiento o problemas técnicos.</li>
                </ul>

                <h2>4. Pagos y reembolsos</h2>
                <p>
                    Los pagos realizados a través de nuestra plataforma son procesados por <strong>Stripe</strong>.  
                    <strong> Pallevar</strong> no almacena datos sensibles como números de tarjetas de crédito o débito. 
                    Cualquier solicitud de reembolso debe ser gestionada directamente con el establecimiento correspondiente.
                </p>
                <p>
                    Para más información sobre pagos, consulta la <a href="https://stripe.com/legal" target="_blank" rel="noopener noreferrer">documentación de Stripe</a>.
                </p>

                <h2>5. Modificaciones</h2>
                <p>
                    Nos reservamos el derecho de modificar estos términos en cualquier momento. Los cambios serán efectivos a partir de su publicación en esta página. 
                    Se recomienda revisar esta página periódicamente para estar al tanto de los cambios.
                </p>

                <h2>6. Propiedad intelectual</h2>
                <p>
                    Todo el contenido de la plataforma, incluyendo texto, imágenes, logotipos y diseño, es propiedad de <strong>Pallevar</strong>. 
                    Está prohibido reproducir, distribuir o usar este contenido sin autorización previa por escrito.
                </p>

                <h2>7. Ley aplicable</h2>
                <p>
                    Estos Términos y Condiciones se rigen por las leyes de México. 
                    Cualquier disputa será resuelta en los tribunales competentes de México.
                </p>

                <h2>8. Contacto</h2>
                <p>
                    Si tienes alguna pregunta sobre estos Términos y Condiciones, puedes ponerte en contacto con nosotros en <strong>soporte@espallevar.com</strong>.
                </p>
                </div>
            </section>
            <FooterSection />
        </>
    );
};

export default TerminosPage;
