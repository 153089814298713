import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const csrfToken = process.env.REACT_APP_API_TOKEN;
 
export const enviaEmails = async (email) => {
 
    try{
   
      const userData = { email };
      const response = await axios.post(`${baseURL}/emailListaEspera/`, userData, {
        mode: "cors",
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
          'Authorization': 'BeareraccessToken',
          'Accept': 'application/json'
        },
      });
  
      return response.data; 
  
      }catch(e){
          if (e.response) {
          throw new Error(e.response.data.message || 'Error al iniciar sesion de usuario');
        } else {
          throw new Error('Error de red o problema con la solicitud');
        }
      }
    };

    export const getMenu = async (id) => {
        try {
            const response = await axios.get(`${baseURL}/menuInteractivo/${id}`, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json',
                    'Authorization': csrfToken,
                    'Accept': 'application/json'
                }
            });  
            
            if (response) {
                return response.data;
            }
            if (response.status === 404){
                console.warn('El producto no fue encontrado para el usuario:', id);
                return null;
            }
        } catch (error) {
            console.error('Error al obtener la información del usuario:', error);
            throw new Error('Error al obtener la información del usuario:', error);
        } 
      }

      export const obtenerHorario = async (id) => {
        const defOut = '{"schedule":{"1":{"open":"00:00","close":"23:00"},"2":{"open":"00:00","close":"23:00"},"3":{"open":"00:00","close":"23:00"},"4":{"open":"00:00","close":"23:00"},"5":{"open":"00:00","close":"23:00"},"6":{"open":"00:00","close":"23:00"},"7":{"open":"00:00","close":"23:00"}}}';
        let out = JSON.parse(defOut);
        console.log("******************")
        try {
            const response = await axios.get(`${baseURL}/horario-operacion/${id}`, {
              headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json',
                    'Authorization': csrfToken,
                    'Accept': 'application/json'
                }
            }); 
            
            if (response && response.data && response.data.schedule) {
                out = response.data;
            } else if (response.status === 404){
                console.warn('El horario no fue encontrado para el usuario:', id);
                //out = null;
            }
        } catch (error) {
            console.error('Error al obtener la información del usuario:', error);
            //throw new Error('Error al obtener la información del usuario:', error);
            //out = null;
        }
        console.log("out:"+JSON.stringify(out))
        return out;
      }

      export const procesaOrden = async (orderData) => {
        try {
          // Extraer los datos de orderData
          const {
            idRestaurant,
            items,
            totalPrice,
            paymentMethod,
            comments,
            contactName,
            contactEmail,
            contactPhone,
          } = orderData;
      
          // Mostrar los datos en la consola para depuración
          console.log(items);
          console.log(idRestaurant, totalPrice, paymentMethod, comments, contactName, contactEmail, contactPhone);
          
          // Enviar los datos como un objeto en el cuerpo de la solicitud
          const response = await axios.post(
            `${baseURL}/procesaOrden/`, 
            {
              idRestaurant,
              items,
              totalPrice,
              paymentMethod,
              comments,
              contactName,
              contactEmail,
              contactPhone,
            },
            {
              mode: "cors",
              headers: {
                'X-CSRFToken': csrfToken,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer accessToken', // Asegúrate de agregar el espacio entre 'Bearer' y 'accessToken'
                'Accept': 'application/json',
              },
            }
          );
      
          return response.data;
        } catch (e) {
          if (e.response) {
            throw new Error(e.response.data.message || 'Error al procesar la orden');
          } else {
            throw new Error('Error de red o problema con la solicitud');
          }
        }
      };
      


export const procesaPago = async (orderData) => {
  
  try{
    const cardDetails = orderData;
    const response = await axios.post(`${baseURL}/procesaPago/`, cardDetails, {
      mode: "cors",
      headers: {
        'X-CSRFToken': csrfToken,
        'Content-Type': 'application/json',
        'Authorization': 'BeareraccessToken',
        'Accept': 'application/json'
      },
    });

    return response.data; 

    }catch(e){
        if (e.response) {
        throw new Error(e.response.data.message || 'Error al iniciar sesion de usuario');
      } else {
        throw new Error('Error de red o problema con la solicitud');
      }
    }
}



export const getMenuColor = async (restaurantId) => { 
  // restaurantId = 0;
  try {
    const response = await axios.get(`${baseURL}/restsettings/${restaurantId}`, {
      headers: {
        'X-CSRFToken': csrfToken,
        'Content-Type': 'application/json',
        'Authorization': `Bearer X`, // Verifica que el token de autorización sea correcto
        'Accept': 'application/json'
      }
    });

    // Verifica si la respuesta tiene un estado 404
    if (response.status === 404) {
      console.log('El color no fue encontrado para el usuario:', restaurantId);
      return null;
    }

    // Si la respuesta fue exitosa, regresa el valor de color
    if (response && response.data) {
       return response.data;
    }

    // Si la respuesta no tiene lo que esperas
    console.log("No se encontró color en la respuesta");
    return null;

  } catch (error) {
    console.error('Error al obtener la información del usuario:', error);
    throw new Error('Error al obtener la información del usuario:', error);
  }
}



export const statusOrden = async (restaurante, orden) => {
  //console.log("restaurante:",restaurante," orden:",orden)
  try {
      const response = await axios.get(`${baseURL}/orderStatus/${restaurante}/${orden}`, {
          headers: {
              'X-CSRFToken': csrfToken,
              'Content-Type': 'application/json',
              'Authorization': csrfToken,
              'Accept': 'application/json'
          }
      });  
      
      if (response) {
          return response;
      }
      if (response.status === 404){
          console.log('El producto no fue encontrado para el usuario:', orden);
          return null;
      }
  } catch (error) {
      console.error('Error al obtener la información del usuario:', error);
      throw new Error('Error al obtener la información del usuario:', error);
  } 
}



export const processPayment = async (paymentData) => {
  console.log(paymentData)
  try {
    const response = await axios.post(`${baseURL}/stripe/procesar-pago/`, paymentData, {
      mode: "cors",
      headers: {
        'X-CSRFToken': csrfToken,
        'Content-Type': 'application/json',
        'Authorization': 'BeareraccessToken',
        'Accept': 'application/json'
      },
    });
    return response.data; // Devuelve la respuesta del backend
  } catch (error) {
    console.error("Error procesando el pago:", error);
    throw error; // Propaga el error para manejarlo en el frontend
  }
};