import React, { useState } from 'react';
import { useParams, useLocation, Link, useNavigate  } from 'react-router-dom';
import { procesaPago, procesaOrden } from "../services/authService";
import styles from "../assets/css/checkout.module.css";
import OrderComments from '../components/Comments';
import CustomerInfo from '../components/CustomerInfo';
import OrderSummary from '../components/OrderSummary';
import PaymentInfo from '../components/PaymentInfo';
import LoadingDots from '../components/LoadingDots';
import PaymentPage from '../components/PaymentPage';

const CheckoutPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);
  
  const [paymentMethod, setPaymentMethod] = useState('');
  const [comments, setComments] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [errors, setErrors] = useState({});
  const [paymentError, setPaymentError] = useState(''); 
  const [isLoading, setIsLoading] = useState(false); 
  


  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value);
    setPaymentError(''); 
  };

  const totalPrice = cartItems.reduce(
    (total, item) => total + parseFloat(item.precio) * item.quantity,
    0
  );

  const validateForm = () => {
    const newErrors = {};
    if (!contactName) newErrors.contactName = 'Nombre de contacto es requerido.';
    if (!contactEmail) newErrors.contactEmail = 'Email es requerido.';
    if (!contactPhone) newErrors.contactPhone = 'Teléfono es requerido.';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!paymentMethod) {
      setPaymentError('Por favor, seleccione un método de pago.');
      return;
    }

    if (!validateForm()) return;
    const itemsToSend = cartItems.map(({ imgPlatillo, ...rest }) => rest); 

    const orderData = {
      idRestaurant: id,
      items: itemsToSend,
      totalPrice: totalPrice.toFixed(2),
      paymentMethod,
      comments,
      contactName,
      contactEmail,
      contactPhone,
      /*cardDetails: paymentMethod === 'tarjeta' ? cardDetails : undefined,*/
    };
    console.log(orderData)
    const paymentData = paymentMethod === 'tarjeta'/* ? { cardDetails } : null*/;

    try {
      // 1. Procesar Pago si es tarjeta de crédito
      if (paymentMethod === 'tarjeta') {
        const paymentResponse = await procesaPago(paymentData); // Llamada a API de pago
        console.log(paymentResponse);
        if (!paymentResponse.success) {
          console.error('Error en el pago:', paymentResponse.data.message);
          return; // Detener el flujo si el pago falla
        }
      }

      // 2. Procesar la Orden después de confirmar el pago exitoso o si es otro método de pago
      const orderResponse = await procesaOrden(orderData);
      console.log(orderResponse);
      console.log('Orden procesada:', orderResponse.orderId);

      // Aquí podrías redirigir al usuario a una página de confirmación o mostrar un mensaje
      if (orderResponse && orderResponse.orderId > 0){
        const ordenId = orderResponse.orderId
        localStorage.removeItem(`cart_${id}`);
        setCartItems([]);
        navigate(`/${id}/orden/${ordenId}`);
      } else {
        console.error("No se recibió un orderId válido:", orderResponse);
      }
  

    } catch (error) {
      console.error('Error al procesar el pago:', error);
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <div>
      <div className={styles.container}>
      <header className={styles.header}>
            <h1 className={styles.title}>
              Checkout
            </h1>
            <p className={styles.subtitle}>
              Termina de procesar tu orden.
            </p>
          </header>

        <div className={styles.grid}>
          <OrderSummary cartItems={cartItems} totalPrice={totalPrice} />
          <OrderComments comments={comments} setComments={setComments} />
          <CustomerInfo 
            contactName={contactName}
            setContactName={setContactName}
            contactEmail={contactEmail}
            setContactEmail={setContactEmail}
            contactPhone={contactPhone}
            setContactPhone={setContactPhone}
            errors={errors}
          />
          <PaymentInfo 
            paymentMethod={paymentMethod} 
            handlePaymentChange={handlePaymentChange} 
            />

          {paymentMethod === 'tarjeta' && (
            <PaymentPage
              orderData={{
                idRestaurant: id,
                items: cartItems.map(({ imgPlatillo, ...rest }) => rest),
                totalPrice: totalPrice.toFixed(2),
                paymentMethod,
                comments,
                contactName,
                contactEmail,
                contactPhone,
              }}
            />
          )}
        </div>

        {paymentError && <p style={{ color: 'red' }}>{paymentError}</p>}
{}
        <button className={styles.buttonConfirm} onClick={handleSubmit} disabled>
          {isLoading ? <LoadingDots /> : 'Procesar Pedido'}
        </button>
        {}
        <p></p>
        <Link to={`/${id}`}>Volver a la página de inicio</Link>
      </div>
    </div>
  );
};

export default CheckoutPage;