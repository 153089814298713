import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../assets/css/confirmation.module.css';
import { statusOrden } from '../services/authService';


const OrderPage = () => {
    const { id, ordenid } = useParams();
    const [orderData, setOrderData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrderStatus = async () => {
            try {
                setLoading(true);
                const data = await statusOrden(id, ordenid);
                console.log(data)
                setOrderData(data?.data); // Accede directamente al objeto de datos
                setLoading(false);
            } catch (err) {
                setError('Hubo un problema al cargar la información de tu pedido.');
                setLoading(false);
            }
        };

        fetchOrderStatus();
    }, [id, ordenid]);

    if (loading) {
        return <p className={styles.loading}>Cargando tu pedido...</p>;
    }

    if (error) {
        return <p className={styles.error}>{error}</p>;
    }

    return (
        
        <div className={styles.page}>
        <div className={styles.container}>
          <header className={styles.header}>
            <h1 className={styles.title}>
              🎉 ¡Gracias por tu Pedido! 🎉
            </h1>
            <p className={styles.subtitle}>
              Tu orden ha sido recibida y está siendo procesada.
            </p>
            <p className={styles.subtitle}>
              Revisa tu email para mas información.
            </p>
          </header>
      
          <section className={styles.orderSummary}>
            <h2 className={styles.summaryTitle}>📋 Resumen de tu Pedido</h2>
            <div className={styles.card}>
              <p>
                <strong>🍴 Restaurante:</strong> {id}
              </p>
              <p>
                <strong>📑 Número de Orden:</strong> {ordenid}
              </p>
              <p>
                <strong>🔄 Estado de la Orden:</strong>{' '}
                <span
                  className={`${styles.orderStatus} ${styles[`status-${orderData?.orderStatus?.toLowerCase()}`]}`}
                >
                  {orderData?.orderStatus}
                </span>
              </p>
              <p>
                <strong>🙋 Nombre del Cliente:</strong> {orderData?.contactName}
              </p>
              <p>
                <strong>💵 Total:</strong> ${Number(orderData?.totalPrice || 0).toFixed(2)} MXN
              </p>
            </div>
          </section>
      
          <section className={styles.orderItems}>
            <h3 className={styles.itemsTitle}>🛒 Detalle de tu Pedido</h3>
            <ul className={styles.itemList}>
              {orderData?.items?.map((item, index) => (
                <li key={index} className={styles.item}>
                   <p>{item.nombrePlatillo} <span className={styles.itemQuantity}> x{item.quantity}</span></p>
                </li>
              ))}
            </ul>
          </section>
      
          <section className={styles.orderEstimate}>
            <h3 className={styles.estimateTitle}>⏳ Tiempo Estimado de Entrega</h3>
            <div className={styles.estimateCard}>
              {orderData?.estimatedDeliveryTime || 'No disponible'}
            </div>
          </section>
      
          <footer className={styles.footer}>
            <button
              className={styles.button}
              onClick={() => (window.location.href = `/${id}`)}
            >
              🔙 Volver al Menú
            </button>
            <button
              className={styles.buttonSecondary}
              onClick={() => (window.location.href = `/${id}/orden`)}
            >
              📜 Ver Mis Órdenes
            </button>
          </footer>
        </div>
      </div>
      
    
    );
};

export default OrderPage;
