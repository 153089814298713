import React from "react";
import styles from "../../assets/css/landingpage/platform.module.css";


const PlatformSection = () => {
    return (
        <section className={styles.systemSection} id="services">
        <h2>Nuestra Plataforma</h2>
            <div className={styles.systemContainer}>
                <div className={styles.systemItem}>
                    <img 
                        src="img/menu.webp" 
                        alt="Menu interactivo"
                        width="" 
                        height="" 
                    />
                    <div className={styles.systemDescription}>
                        <h3>Menú Interactivo</h3>
                        <p>Facilita a tus clientes la exploración y selección de platillos desde cualquier dispositivo.</p>
                    </div>
                </div>
                <div className={styles.systemItem}>
                    <img 
                        src="img/comanda.webp" 
                        alt="Comanda digital"
                        width="" 
                        height="" 
                    />
                    <div className={styles.systemDescription}>
                        <h3>Comanda Digital</h3>
                        <p>Automatiza la gestión de pedidos y agiliza el servicio directamente en la cocina.</p>
                    </div>
                </div>
                <div className={styles.systemItem}>
                    <img 
                        src="img/panel.webp" 
                        alt="Panel Administrativo"
                        width="" 
                        height="" 
                    />
                    <div className={styles.systemDescription}>
                        <h3>Panel Administrativo</h3>
                        <p>Controla todos los aspectos del restaurante, desde el inventario hasta el análisis de ventas.</p>
                    </div>
                </div>
            </div>
        </section>
        );
    };
    
    export default PlatformSection;
    