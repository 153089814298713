import React from "react";
import styles from "../../assets/css/landingpage/pricing.module.css";

const PricingSection = () => {
    return (
        <section className={styles.pricingSection} id="precios">
            <h2>Planes Accesibles para tu Negocio</h2>
            <p className={styles.subtitle}>Tecnología de calidad, al alcance de todos</p>
            <div className={styles.pricingContainer}>
                <div className={styles.planCard}>
                    <h5>Emprendedor</h5>
                    <p className={styles.cardPrice}>
                        $399 <span className={styles.billingPeriod}>/ Mes</span>
                    </p>
                    <button 
                        className={`${styles.btn} ${styles.btnPrimary}`}
                        onClick={() => window.open("https://admin.espallevar.com/signup", "_blank")}
                    >
                        Iniciar Prueba Gratis
                    </button>
                    <ul>
                        <li>✅ Gestión de Pedidos</li>
                        <li>✅ Panel Administrativo</li>
                        <li>✅ Menú Interactivo</li>
                        <li>✅ Soporte por Email</li>
                        <li>✅ Reportes Básicos</li>
                        <li>✅ Hasta 2 Usuarios</li>
                        <li>✅ Prueba Gratuita de 3 Meses</li>
                        <li>✅ Comanda Digital</li>
                        <li>✅ Notificacion al cliente via Mail</li>
                        <li>✅ 1 Usuario Administrativo</li>
                        <li>✅ 1 Menú por Restaurante</li>
                    </ul>
                </div>

                <div className={styles.planCard}>
                    <h5>Negocio en Crecimiento</h5>
                    <p className={styles.cardPrice}>
                        $699 <span className={styles.billingPeriod}>/ Mes</span>
                    </p>
                    <button 
                        className={`${styles.btn} ${styles.btnPrimary}`}
                        onClick={() => window.open("https://admin.espallevar.com/signup", "_blank")}
                    >
                        Iniciar Prueba Gratis
                    </button>
                    <ul>
                        <li>✅ Todo en Emprendedor</li>
                        <li>✅ Soporte por Email y Chat</li>
                        <li>✅ Reportes Avanzados</li>
                        <li>✅ Hasta 6 Usuarios</li>
                        <li>✅ Gestión Básica de Inventario</li>
                        <li>✅ Integración Inicial con POS</li>
                        <li>✅ Gestión de Hasta 2 Sucursales</li>
                        <li>✅ Integración con Puntos de Venta</li>
                        <li>✅ Gestión de Inventarios</li>
                        <li>✅ SMS al Cliente</li>
                        <li>✅ 3 Usuarios Administrativos</li>
                        <li>✅ Hasta 2 Menús por Restaurante</li>
                    </ul>
                </div>

                <div className={styles.planCard}>
                    <h5>Expansión Total</h5>
                    <p className={styles.cardPrice}>
                        $999 <span className={styles.billingPeriod}>/ Mes</span>
                    </p>
                    <button 
                        className={`${styles.btn} ${styles.btnPrimary}`}
                        onClick={() => window.open("https://admin.espallevar.com/signup", "_blank")}
                    >
                        Iniciar Prueba Gratis
                    </button>
                    <ul>
                        <li>✅ Todo en Negocio en Crecimiento</li>
                        <li>✅ Hasta 10 Usuarios</li>
                        <li>✅ Hasta 6 Usuarios Administrativos</li>
                        <li>✅ Gestión Avanzada de Inventarios</li>
                        <li>✅ Gestión de Hasta 6 Sucursales</li>
                        <li>✅ Integración Completa con POS</li>
                        <li>✅ Reportes Personalizados</li>
                        <li>✅ Soporte Prioritario</li>
                        <li>✅ Integración con Plataformas de Delivery</li>
                        <li>✅ Analítica Avanzada de Ventas</li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default PricingSection; 